import React from 'react'
import Logo from '../../images/logo-footer.png';
import { NavLink } from 'react-router-dom';
const FooterMap = () => {
    return (
        <>
            <section className="where">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 logo align-items-center">
                            <img src={Logo} alt='Footer Logo' />
                        </div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md-6 text-white">
                                    <div className="office">
                                        <address>
                                            <h5>New Delhi, India</h5>
                                            <hr />
                                            <p>C-7/307, Ground Floor, Keshav Puram, <br /> Delhi - 110035<br /> Phone: +91 9953609096</p>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-md-6 text-white bg-dark">
                                    <div className="office">
                                        <address>
                                            <h5>Follow Us On</h5>
                                            <hr />
                                            <ul className="social-icon-list mt-4">
                                                <li>
                                                    <NavLink className="social-icon faa-facbook" to={`https://www.facebook.com/nerditia`} target="_blank"><i className="fa fa-facebook" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-google" to={`https://www.instagram.com/nerditians/`} target="_blank"><i className="fa fa-instagram" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-linkedin" to={`https://www.linkedin.com/company/nerditia`} target="_blank"><i className="fa fa-linkedin" /></NavLink>
                                                </li>
                                                <li>
                                                    <NavLink className="social-icon faa-youtube" to={`https://www.youtube.com/@nerditians`} target="_blank"><i className="fa fa-youtube" /></NavLink>
                                                </li>
                                            </ul>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* START MAP */}
            <div className="map-container">
            <iframe title='Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.894057427088!2d77.15623977542762!3d28.692815575631826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0230b6fcf075%3A0x2d8637d9156d1be3!2sb%2C%204%2C%20Lawrence%20Rd%2C%20Pocket%20C7%2C%20Block%20C6%2C%20Karampura%20Industrial%20Area%2C%20Karam%20Pura%2C%20Delhi%2C%20110035!5e0!3m2!1sen!2sin!4v1686054212109!5m2!1sen!2sin" width="100%" height={150} style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>{/* END MAP */}

            
        </>
    )
}

export default FooterMap