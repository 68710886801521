import React from 'react'

const WebsiteMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center aos-init aos-animate" data-aos="fade-right">
                            <div className="app-development-title">
                                Website Design &amp; development
                            </div>
                            <h5>Website development services &amp; solution in Delhi that make your business boom</h5>
                            <p>Nerditia is one such website Development Company that provides quality web development services for your business with meticulous planning and perfect execution of professional web developers.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WebsiteMain