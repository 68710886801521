import React from 'react'

const AboutBanner = () => {
    return (
        <>
            <div className="about-us-banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mb-5">
                            <div className="email-services-inner">
                                <h2 data-animation="animated zoomInLeft">About Us</h2>
                                <h5>We design and develop Web for All</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutBanner