import React from 'react'
import SeoPic from '../../../images/seo-banner.png';

const SeoBanner = () => {
    return (
        <>
            <div className="banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="android-app-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-7">
                                        <img src={SeoPic} alt="Seo" style={{ width: 'auto' }} />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="php-web-app">
                                            <h2>Search Engine Optimisation</h2>
                                            <h5>We would be happy to hear from you, Please fill in the form below or mail us your requirements on</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SeoBanner