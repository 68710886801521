import React from 'react'

const AboutMain = () => {
    return (
        <>
            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-3">
                            <div className="app-development-title aos-init aos-animate" data-aos="fade-left">
                                Nerditia – The Top SMM Company in India
                            </div>
                            <p data-aos="zoom-in" className="aos-init aos-animate">At Nerditia, innovation is not just a jargon; rather it is a core element of our organizational professionalism. A venture, which was pioneered in the year 2017, continues with disciplined professional values and keeps on ascending with new achievements. We always look forward to taking our organizational objectives ahead in a manner that ushers in creating value-based relationships with our clients. The journey started with five dedicated professionals, and currently, after 2.5 years.</p>
                            <p data-aos="zoom-in" className="aos-init aos-animate">Nerditia serves leading enterprises across key industries by providing a range of services such as digital marketing, development, and designing. We believe in the importance of faith, transparency, suppleness and professional values. Our organizational values are based on the ‘Employees First’ attitude, which makes sure that our efforts to meet our clients’ interests are being executed perfectly. And this is the strength that leads us to help our esteemed clients grow their business in the digital age. We keep on taking up new technologies and tools to make our endeavour more productive and effective.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-development">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12 text-center mb-3">
                            <div className="app-development-title aos-init aos-animate" data-aos="fade-left">
                                Nerditia – Something About the Founders
                            </div>
                            <p data-aos="zoom-in" className="aos-init aos-animate"> <strong>Sachin Gupta</strong> - Sachin is the trailblazing founder, creative genius and relentless innovator behind Nerditia, where digital dreams become reality. He is the maestro of code and a pioneer in digital innovation. Sachin is Armed with a degree in Computer Science from MIT and an insatiable curiosity, she embarked on a mission to transform the digital landscape. Outside the digital realm, he's a skilled dancer and a dedicated marathon runner, constantly pushing the boundaries of both technology and personal endurance.</p>
                            <p data-aos="zoom-in" className="aos-init aos-animate"><strong>Rishabh Gupta</strong> - Rishabh is the co-founder and brings a futuristic vision to the company's technology roadmap. He is known for his unconventional thinking and infectious energy that has led Nerditia to the forefront of the industry, creating technologies that not only solve today’s challenges but also anticipate tomorrow’s needs. In his spare time, he’s a self-taught musician and an avid explorer of virtual reality realms.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutMain