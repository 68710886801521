import React from 'react'

const CMarketingMain = () => {
    return (
        <>
            <div className="email-development">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="email-development-title aos-init aos-animate" data-aos="zoom-in">
                                Best Web Content Writing Services Only at Nerditia
                            </div>
                            <div className="email-development-sub-title pt-3 aos-init aos-animate" data-aos="fade-up">
                                The Content Management Services offered by our company Markup Designs is amidst the best content writing services in Delhi. Today not only the clients in the E-commerce business but also the clients from the different business segments avail the content writing services due to the noteworthy growing demand of the plagiarism free and high quality contents in the highly competitive domestic and international markets.
                                <br />
                                We very well understand the need and the limitless importance of high quality and plagiarism free contents. Our team of professional content writers in India is creative enough to hold the attention of readers through their words. Their words cast a magical spell and leave a powerful imprint onto the minds of users. Can’t figure it out how they come up with such great ideas and thoughts? Get ready to rule the Digital Kingdom with our incredible Content Management strategies.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CMarketingMain