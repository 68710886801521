import React from 'react'
import Menu from '../../Menu/Menu'
import { NavLink } from 'react-router-dom'
const Banner = () => {
    return (
        <>
            <Menu></Menu>
            <div className="banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="banner-text">
                            <div className="banner-inner">
                                <h1 className="pt-4">Digital Marketing Studio<br></br> in Delhi Since 2017</h1>
                                <h6 className="mb-5">Nerditia is a leading Digital & Content Marketing company, creating SEO friendly content and driving more traffic to the companies of all sizes. Our dedicated team has the experience, know-how and passion to deliver high quality output to achieve optimal results for both your company profile and your bottom line.We are aiming to be the best Content & Digital Marketing company in Delhi.</h6>
                                <NavLink className="btn-style02 btn-slide" to={'/contact'}> free consultation </NavLink>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default Banner