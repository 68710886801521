import './App.css'
import AOS from 'aos'
import './animation-aos.css'
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/Home/Home'
import About from './components/About/About'
import Contact from './components/Contact/Contact'
import Services from './components/Services/Services'
import AndroidDevelopment from './components/Services/AndroidDevelopment/AndroidDevelopment'
import IosDevelopment from './components/Services/IosDevelopment/IosDevelopment'
import PhpDevelopment from './components/Services/PhpDevelopment/PhpDevelopment'
import WebDevelopment from './components/Services/WebsiteDevelopment/WebDevelopment'
import SeoDevelopment from './components/Services/Seo/SeoDevelopment'
import SmmDevelopment from './components/Services/Smm/SmmDevelopment'
import EmarketingDevelopment from './components/Services/EmailMarketing/EmarketingDevelopment'
import CMarketingDevelopment from './components/Services/ContentMarketing/CMarketingDevelopment'
import BlogMain from './components/Inner-Pages/Blog-pages/BlogMain'
import BlogDetails from './components/Inner-Pages/Blog-pages/BlogDetails';


AOS.init();

function App() {
  
  return (
    <>
       <Routes>      
        {/* <Route path="/" element={<Navbar />} /> */}
        <Route path="/" element={<Home />} /> 
        <Route path="/about" element={<About /> } /> 
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact /> } /> 
        <Route path='/android-app-development' element={<AndroidDevelopment /> } />
        <Route path='/iso-app-development' element={<IosDevelopment />} />
        <Route path='/php-development' element={<PhpDevelopment />} />
        <Route path='/web-development' element={<WebDevelopment />} />
        <Route path='/seo' element={<SeoDevelopment />} />
        <Route path='/social-media-marketing' element={<SmmDevelopment />} />
        <Route path='/email-marketing' element={<EmarketingDevelopment /> } />
        <Route path='/content-marketing' element={<CMarketingDevelopment />} />
        <Route path='/blog' element={<BlogMain />} />
        <Route path='/blogdetails' element={<BlogDetails />} />
      </Routes>
    </>
  );
}

export default App;
