import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Menu.css'
import Logo from '../../images/logo-main.png'

const Header = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    return (
        <>
            {/* partial:index.partial.html */}
            {/* <div id="main-menu" className="main-menu">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="logo">
                            <NavLink to={'/'}><img src={Logo} alt='logo' /></NavLink>
                        </div>
                        <div className="header-right justify-content-end">
                            <div className="header-right-bar">
                                <i className="fa fa-mobile" /> +91-9953609096
                            </div>
                        </div>

                        <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon" />
                        <label for="menu-icon"></label>
                        <nav className="nav">
                            <ul className="pt-5">
                                <li><NavLink to={`/`}>Home</NavLink></li>
                                <li><NavLink to={`/about`}>About</NavLink></li>
                                <li><NavLink to={`/services`}>Service</NavLink></li>
                                <li><NavLink to={`/contact`}>Contact</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div> */}


            <nav className="navbar">
                <div className="nav-container">
                    <NavLink exact to="/" className="nav-logo">
                        <img src={Logo} alt='logo' />
                    </NavLink>

                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/about"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                About
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/#"
                                activeClassName="active"
                                className="nav-links"
                            >
                                Services

                                <ul className="drop-menu">
                                    <li><NavLink to="/android-app-development">Android App Development</NavLink></li>
                                    <li><NavLink to="/iso-app-development">iOS App Development</NavLink></li>
                                    <li><NavLink to="/php-development">Php Web Development</NavLink></li>
                                    <li><NavLink to="/web-development">Website Development</NavLink></li>
                                    <li><NavLink to="/seo">Search Engine Optimisation</NavLink></li>
                                    <li><NavLink to="/social-media-marketing">Social Media Marketing</NavLink></li>
                                    <li><NavLink to="/email-marketing">E-mail Marketing</NavLink></li>
                                    <li><NavLink to="/content-marketing">Content Writing</NavLink></li>
                                </ul>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/contact"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav-icon" onClick={handleClick}>
                        <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header