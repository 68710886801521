import React from 'react'

// import AndroidIcon from '../../images/android-icon.png'
// import IosIcon from '../../images/ios-icon.png'
// import WebDev from '../../images/web-development-icon.png'
// import WebDesign from '../../images/web-design.png'
// import Seo from '../../images/seo-icon.png'
// import Sms from '../../images/smm-icon.png'
// import EmailMarketing from '../../images/email-marketing.png'
// import ContentMarketing from '../../images/content-marketing.png'
const Menu = () => {

    return (
        <>

            {/* <nav className="navigation">
                <span className="hamburger-menu">
                    <span className="burger-1" />
                    <span className="burger-2" />
                    <span className="burger-3" />
                </span>
                <ul className="core-menu">
                    <li><a href="index.html">Home</a></li>
                    <li><a href="about-us.html">About Us</a></li>
                    <li><a href="#" >Services<span className="toggle" /></a>
                        <ul className="dropdown">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-3">
                                        <a href="android-app-development.html">
                                            <img src={AndroidIcon} alt="Android Icon" />
                                            <h5>Android App Development</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="iso-app-development.html">
                                            <img src={IosIcon} alt="IOS Icon" />
                                            <h5>iOS App Development</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="php-web-development.html">
                                            <img src={WebDev} alt="Web Development" />
                                            <h5>Php Web Development</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="website-development.html">
                                            <img src={WebDesign} alt="Web Design" />
                                            <h5>Website  Development</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="search-engine-optimisation.html">
                                            <img src={Seo} alt="Seo" />
                                            <h5>Search Engine Optimisation</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="social-media-marketing.html">
                                            <img src={Sms} alt="sms" />
                                            <h5>Social Media Marketing</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="email-marketing.html">
                                            <img src={EmailMarketing} alt="Email Marketing" />
                                            <h5>E-mail Marketing</h5>
                                        </a>
                                    </div>
                                    <div className="col-md-3">
                                        <a href="content-marketing-services.html">
                                            <img src={ContentMarketing} alt="Content Marketing" />
                                            <h5>Content Writing</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>
                    <li><a href="#">Career</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Blog</a></li>
                </ul>
            </nav> */}

        </>
    )
}

export default Menu