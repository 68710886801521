import React from 'react'
import PhpPic from '../../../images/web-development-banner.png' 
const PhpBanner = () => {
    return (
        <>
            <div className="banner">
                <div className="container-fluid">
                    <div className="row justify-content-center">                       
                        <div className="php-web-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={PhpPic} alt='PhpBanner' />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="php-web-app">
                                            <h2>Php Website Design Company</h2>
                                            <h5>We would be happy to hear from you, Please fill in the form below or mail us your requirements on</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PhpBanner