import React from 'react'
import CallMckup from '../../../images/call-mockup-img.png'
import { NavLink } from 'react-router-dom'
const CreateStory = () => {
    return (
        <>
            <section className="partnership-models pb-0 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7" data-aos="fade-left">
                            <div className="partnership-title">
                                <h3>Let’s Create Big Stories Together </h3>
                                <p>Mobile is in our nerves. We don’t just build apps, we create brand. Choosing us will be your best decision.</p>
                                <NavLink to={'/contact'} className="btn btn-white mt-4">Get A Quote</NavLink>
                            </div>
                        </div>

                        <div className="col-md-5" data-aos="fade-up">
                            <img src={CallMckup} alt='CallMckup' />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateStory