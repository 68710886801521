import React from 'react'
import { NavLink } from 'react-router-dom'
import FooterMap from './FooterMap'
import FooterTop from './FooterTop'

const Footer = () => {
    return (
        <>
            <FooterTop></FooterTop>
            <FooterMap></FooterMap>

            <div className="py-2 footer-bottom">
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                <li>
                                    <NavLink to={`#`}>Privacy Policy</NavLink>
                                </li> |
                                <li>
                                    <NavLink to={`#`}>Terms Conditions</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <p className="text-white"> Copyright © 2017, All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer