import React from 'react'
import { NavLink } from 'react-router-dom'

const FooterTop = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="footer-col" data-aos="fade-right">
                            <div className="footer-header">About</div>
                            <ul className="footer-lists">
                                <li><NavLink to={`/about`}>About Us</NavLink></li>
                                <li><NavLink to={`/contact`}>Contact Us</NavLink></li>
                                <li><NavLink to={`/`}>Blog</NavLink></li>
                            </ul>
                        </div>

                        <div className="footer-col" data-aos="fade-up">
                            <div className="footer-header">App & Web Services</div>
                            <ul className="footer-lists">
                                <li><NavLink to={`/android-app-development`}>Android App Development</NavLink></li>
                                <li><NavLink to={`/iso-app-development`}>iOS App Development</NavLink></li>
                                <li><NavLink to={`/php-development`}>PHP Development</NavLink></li>
                                <li><NavLink to={`/website-development`}>Website Development</NavLink></li>
                            </ul>
                        </div>

                        <div className="footer-col" data-aos="fade-up">
                            <div className="footer-header">Digital Marketing </div>
                            <ul className="footer-lists">
                                <li><NavLink to={`/seo`}>Search Engine Optimisation</NavLink></li>
                                <li><NavLink to={`/social-media-marketing`}>Social Media Marketing</NavLink></li>
                                <li><NavLink to={`/email-marketing`}>Email Marketing</NavLink></li>
                                <li><NavLink to={`/content-marketing`}>Content Writing</NavLink></li>
                            </ul>
                        </div>
                        <div className="footer-form-col" data-aos="fade-left">
                            <div className="footer-header">Let's Start a Conversation</div>

                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <input id="name" name="name" className="form-control" placeholder="Your Fullname*" type="text" value="" />
                                            <span className="name-error"> </span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-field">
                                            <input id="name" name="name" className="form-control" placeholder="Your Contact No*" type="text" value="" />
                                            <span className="name-error"> </span>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <input id="name" name="name" className="form-control" placeholder="Your Email*" type="text" value="" />
                                            <span className="name-error"> </span>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input-field">
                                            <textarea id="name" name="name" className="form-control" placeholder="Your Message*" type="text" value="">
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <NavLink to={`/contact`} className="btn btn-white"> Get A Quote </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterTop